import { useContext } from "react";
import { AuthContext } from "../App";
import { AUTH_HEADER, baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import { isAuthorize } from "./useUserActions";

function useCollabActions() {
  // const { userDetails, updateUserContext } = useContext(AuthContext);
  const headers = AUTH_HEADER;

  const getExitingCollabs = async () => {
    try {
      return await axios.get(`${baseURL}/collaborations`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getCollabsRequests = async () => {
    try {
      return await axios.get(`${baseURL}/collaborations`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const searchProviderForCollab = async (queryString) => {
    try {
      return await axios.get(
        `${baseURL}/collaborations/users?search=${queryString}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const sendCollabRequest = (drId) => {
    return axios
      .post(
        `${baseURL}/collaborations/send_request`,
        {
          dr_user_id: drId,
        },
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  return {
    getExitingCollabs,
    searchProviderForCollab,
    sendCollabRequest,
    getExitingCollabs,
    getCollabsRequests,
  };
}

export default useCollabActions;
