import { useContext } from "react";
import { AuthContext } from "../App";
import { baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
const CryptoJS = require("crypto-js");

export const isAuthorize = (err) => {
  if (err.toJSON().status === 401) {
    toast.error("Unauthorized");
    logout();
  }
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.assign("/");
};

const useUserActions = () => {
  const { updateUserContext } = useContext(AuthContext);

  const auth_token = localStorage.getItem("auth-token");

  const headers = {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  };

  const update = (data) => {
    // const encryptedData = {
    //   ...data,
    //   first_name: CryptoJS.AES.encrypt(
    //     data.firstName ? data.firstName : "",
    //     process.env.REACT_APP_CRYPTO_KEY
    //   ).toString(),
    // };

    return axios
      .put(`${baseURL}/users/update_info`, data, headers)
      .then((data) => {
        updateUserContext(data.data.data);
        // localStorage.setItem("user-details", JSON.stringify(data.data));
        toast.success(data.data.messages);
        return data.data.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };
  const getUser = async () => {
    try {
      const result = await axios.get(`${baseURL}/users`, headers);
      if (result && result.data && result.data.user)
        localStorage.setItem("user-details", JSON.stringify(result.data.user));
      return result;
    } catch (err) {
      toast.error("Unauthorized");
      logout();
    }
  };
  const getStateByZip = async (zip) => {
    try {
      return await axios.get(`${baseURL}/city_state_by_zip/${zip}`, headers);
    } catch (err) {
      isAuthorize(err);
    }
  };

  const changePassword = (payload) => {
    return axios
      .post(`${baseURL}/change_password`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  return { update, getUser, logout, getStateByZip, changePassword };
};
export default useUserActions;
