import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import _ from "lodash";

function Specialties() {
  const { getMasterSpecialtiesList } = useProviderActions();
  const [allSpecialties, setAllSpecialties] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState(["10"]);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const { updateSpecialties } = useProviderActions();
  const { userDetails } = useContext(AuthContext);

  const handleUpdate = (e) => {
    console.log("---handleUpdate--");
    const tempData = [...selectedSpecialties];
    if (e.target.checked) {
      tempData.push(e.target.name);
    } else {
      _.remove(tempData, (x) => x === e.target.name);
    }
    setSelectedSpecialties([...tempData]);
    console.log("--updating");
    updateSpecialties({ user_id: userDetails.user_id, specialities: tempData });
  };
  useEffect(() => {
    getMasterSpecialtiesList().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setAllSpecialties(res.data);
        }
      }
    });
  }, []);

  return (
    <div className="my-4  px-10 max-md:px-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">Specialties</div>
        <div className="bg-gray_out p-2 rounded-md my-2 flex flex-col gap-3 pl-10">
          <div className="w-full flex flex-wrap max-md:flex-col">
            {allSpecialties.map((item, index) => (
              <div
                className="w-1/3 pr-10 py-1 max-md:flex  max-md:gap-2 max-md:pr-2 max-md:w-full"
                key={item.id}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer px-1"
                  onChange={handleUpdate}
                  name={item.id}
                  id={item.id}
                  defaultChecked={selectedSpecialties.includes(item.id)}
                />
                <label htmlFor={item.id}>{item.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="text-right max-md:text-start">
          To personalize your Search Optimization Preference,{" "}
          <a target="_blank" className="text-mid_tone_two" href="/">
            click here.
          </a>
        </div>
      </section>
    </div>
  );
}

export const specialtiesArr = [
  "Addiction/SUDs",
  "Eating Disorders",
  "Panic Attacks",
  "Identity struggles (Race, gender, orientation etc.)",
  "Family conflicts",
  "PTSD",
  "Seasonal Affective Disorder",
  "Addiction/SUDs",
  "Eating Disorders",
  "Panic Attacks",
  "Identity struggles (Race, gender, orientation etc.)",
  "Family conflicts",
  "PTSD",
  "Seasonal Affective Disorder",
  "Addiction/SUDs",
  "Eating Disorders",
  "Panic Attacks",
  "Family conflicts",
  "PTSD",
  "Seasonal Affective Disorder",
  "Addiction/SUDs",
  "Eating Disorders",
  "Panic Attacks",
  "Identity struggles (Race, gender, orientation etc.)",
  "Family conflicts",
  "PTSD",
  "Seasonal Affective Disorder",
];

export default Specialties;
