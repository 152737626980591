import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import useUserActions from "../../../../../actions/useUserActions";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { ProfilePictureUploader } from "../../../../../components/ProfilePictureUploader";
import ProfilePicture from "../../../../../components/ProfilePicture";
import { AuthContext } from "../../../../../App";
import {
  GENDERS,
  PREFIXES,
  PRO_NOUNS,
  SUFFIXES,
} from "../../../../forms/registration-and-verification/utils";

import FormInputComponent from "../../../../forms/registration-and-verification/FormInputComponent";
import FormButtonComponent from "../../../../forms/registration-and-verification/FormButtonComponent";
import ModalError from "../../../../../components/modals/ModalError";
function PersonalDetails({ userDetails }) {
  const { userDetails: user } = useContext(AuthContext);
  const initialData = {};
  if (user && user.category_id && user.category_id > -1) {
    initialData["label"] = user.category_name;
    initialData["value"] = user.category_id;
  }
  const [selectedCategory, setSelectedCategory] = useState(initialData);

  const { update: updatePersonalDetails } = useUserActions();
  // const [userDetails, setUser] = useState(null);
  const [pp, setPp] = useState({ data_url: user.picture ?? "" });
  const [gender, setGender] = useState({});
  const [pronounce, setPronounce] = useState({});
  const [updates, setUpdates] = useState({});

  const handleChange = (name, value) => {
    setUpdates({ ...updates, [name]: value });
  };

  const onImageChange = (img) => {
    if (img && img[0]) {
      setPp(img[0]);
    } else {
      setPp({ data_url: "none" });
    }
  };
  const [errorModal, setErrorModal] = useState(false);
  const onSubmit = (data) => {
    if (
      selectedCategory === undefined ||
      selectedCategory.value == undefined ||
      selectedCategory.value < 0
    ) {
      setErrorModal(true);
      return;
    }
    const payload = {
      category: selectedCategory.value,
      ...data,
      ...updates,
    };
    if (pp.data_url) {
      payload["picture"] = pp.data_url;
    }
    updatePersonalDetails(payload);
  };
  if (errorModal === true) {
    return (
      <ModalError
        close={() => setErrorModal(false)}
        message={"Please select Provider Category"}
      />
    );
  }
  return userDetails === null ? (
    <ClipLoader
      color={"black"}
      loading={true}
      size={100}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  ) : (
    <div className="h-full bg-white w-11/12 rounded-lg py-10">
      <span className="block font-semibold text-xxl	pl-10">Personal</span>
      <span className="pl-10">Provider ID: {userDetails.user_id}</span>
      <div className="py-[1.5px] ml-10 mt-2 bg-gray_out w-11/12" />
      <div className="flex justify-between py-4 px-10 text-gray_out_text items-center">
        <span>Profile Picture</span>

        <span>
          <ProfilePictureUploader
            picture={user.picture}
            onImageChange={onImageChange}
          />
        </span>
        {user.picture &&
        pp.data_url === user.picture &&
        pp.data_url !== "none" ? (
          <div className="flex">
            <button
              onClick={() => setPp({ data_url: "none" })}
              className="mx-2 px-2 rounded bg-complementary_accent_three text-gray_out"
            >
              Delete Profile Picture
            </button>
            <img src={user.picture} width={40} />
          </div>
        ) : null}
        <span>
          {!userDetails.picture ? (
            <img src={userDetails.picture}></img>
          ) : (
            <ProfilePicture />
          )}
        </span>
      </div>
      <PersonalDetailsVerification
        onSubmit={onSubmit}
        isEditing={true}
        setIsEditing={() => null}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleChange={handleChange}
        isCatEditable={initialData.id ? false : true}
        userDetails={userDetails}
      />
    </div>
  );
}

export default PersonalDetails;

function PersonalDetailsVerification({
  onSubmit,
  isEditing,
  handleChange,
  userDetails,
  selectedCategory,
  setSelectedCategory,
  isCatEditable,
}) {
  const { user } = userDetails;
  const { getStateByZip } = useUserActions();
  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
  };

  const { register, handleSubmit } = useForm();
  if (!userDetails) return;

  // const decryptedFirstName = CryptoJS.AES.decrypt(
  //   userDetails.decrypt ? userDetails.first_name : "",
  //   process.env.REACT_APP_CRYPTO_KEY
  // ).toString();
  // const handleZIPchange = async (zipValue) => {
  //   if (zipValue.length === 5) {
  //     const zipApiResponse = await getStateByZip(zipValue);
  //     // console.log(zipApiResponse);
  //   }
  // };

  const [catData, setCatData] = useState([]);

  const categoryOptions = catData.map((category) => ({
    label: category.category_name,
    value: category.category_id,
  }));

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {})
      .then((res) => res.json())
      .then((data) => {
        setCatData(data);
      });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-md:mx-10 ">
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  ">
        <div
          className="
        flex gap-5 justify-start max-md:px-2  max-md:flex-col flex-wrap w-full"
        >
          <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"prefix"}
            >
              Prefix
            </label>

            <Select
              options={PREFIXES}
              className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
              id="prefix"
              name="prefix"
              placeholder=""
              isDisabled={!isEditing}
              onChange={(e) => handleChange("prefix", e.value)}
              defaultValue={PREFIXES.find((x) => x.value === user.prefix)}
            />
          </div>
          <FormInputComponent
            register={register}
            inputFor={"firstName"}
            isDisabled={!isEditing}
            inputName="first_name"
            inputLabel={"First Name"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="firstName"
            inputAutocomplete="firstName"
            // validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={user.first_name}
          />{" "}
          <FormInputComponent
            isDisabled={!isEditing}
            register={register}
            inputFor={"midName"}
            inputName="middle_name"
            inputLabel={"Middle Name"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="midName"
            inputAutocomplete="midName"
            // validations={{ required: false }}
            inputPlaceholderP=""
            defaultValue={user.middle_name}
          />{" "}
          <FormInputComponent
            register={register}
            inputFor={"lastName"}
            inputName="last_name"
            inputLabel={"Last Name"}
            inputType="text"
            className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="lastName"
            inputAutocomplete="lastName"
            // validations={{ required: false }}
            defaultValue={user.last_name}
            inputPlaceholder=""
            isDisabled={!isEditing}
          />
          <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"suffix"}
            >
              Suffix
            </label>
            <Select
              options={SUFFIXES}
              className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
              id="suffix"
              placeholder=""
              isDisabled={!isEditing}
              onChange={(e) => handleChange("suffix", e.value)}
              defaultValue={SUFFIXES.find((x) => x.value === user.suffix)}
            />
          </div>
          <div className="w-36">
            <div>Gender</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="gender"
                defaultValue={GENDERS.find((x) => x.value === user.gender)}
                onChange={(e) => handleChange("gender", e.value)}
                options={GENDERS}
              />
            </div>
          </div>
          <div className="w-36">
            <div>Pronoun</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="pronoun"
                defaultValue={PRO_NOUNS.find((x) => x.value === user.pronoun)}
                onChange={(e) => handleChange("pronoun", e.value)}
                options={PRO_NOUNS}
              />
            </div>
          </div>
          <FormInputComponent
            register={register}
            inputFor={"birthdate"}
            inputName="birthdate"
            inputLabel={"Date of Birth"}
            inputType="date"
            className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="birthdate"
            inputAutocomplete="birthdate"
            inputPlaceholderP=""
            onChange={(e) => handleChange("birthdate", e.value)}
            defaultValue={user.birthdate}
          />{" "}
        </div>

        <Select
          classNames={{
            control: (state) =>
              classNames(
                {},
                "w-60 h-10 rounded-xl select-box-upload-doc  max-md:w-80 my-4"
              ),
          }}
          placeholder="Provide Category"
          options={[selectedCategory]}
          // options={categoryOptions}
          value={selectedCategory}
          onChange={() => {}}
        />

        <div className="mt-2 flex gap-3 max-md:px-2 ">
          <FormButtonComponent
            ButtonType="submit"
            className="text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
            ButtonValue="Save"
          ></FormButtonComponent>
        </div>
      </div>
    </form>
  );
}
