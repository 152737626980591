import React, { useContext, useEffect, useState } from "react";
import ProfilePicture from "../../../../components/ProfilePicture";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { ClipLoader } from "react-spinners";
import { Divider, Skeleton } from "@mui/material";
import { CheckIcon } from "@heroicons/react/20/solid";

function MedicalProfileWidget() {
  const { userDetails } = useContext(AuthContext);

  const [providerDetails, setProviderDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { getProviderDetails } = useProviderActions();

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProviderDetails(userDetails.user_id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setProviderDetails(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  if (isLoading || providerDetails === null) {
    return <Skeleton variant="rectangular" width={480} height={380} />;
  }

  if (providerDetails)
    return (
      <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-full">
        <div className="text-subtitle_one">Medical Profile</div>
        <div className="my-2">
          <div className="flex gap-2  items-center">
            <ProfilePicture picture={userDetails.picture} />
            <div>
              <p className="font-normal">
                <span className="text-gray_out_text">
                  {providerDetails.user.category_name}
                </span>
              </p>
              <p className="font-normal">
                <span className="text-gray_out_text">
                  {providerDetails.specialities.join(",")}
                </span>
              </p>
            </div>
          </div>
          <div className="my-2">
            <Divider />
          </div>
          <div className=" ">
            <span className="flex flex-col gap-0.5">
              <p className="body_font_three flex gap-1 items-center">
                <div className="font-bold">
                  <CheckIcon width={16} />
                </div>
                Medical Registration Verified
              </p>
              <p className="body_font_three flex gap-1 items-center">
                <img
                  src={"/assets/images/check-tick.png"}
                  className="h-3 w-3 "
                  aria-hidden="true"
                />
                95% (227 patients)
              </p>
              <p className="text-gray_out_text font-normal">
                {providerDetails.profile.introduction_for_patient}
              </p>
            </span>
          </div>
          <img
            className="w-full h-0.5 my-5"
            src="/assets/images/login-img-3-1.png"
          ></img>
          <div className="flex justify-end mb-2">
            <Link
              to="/provider/profile-management/medical-profile"
              className="flex justify-end mb-2"
            >
              <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    );

  return null;
}

export default MedicalProfileWidget;
