import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import { useForm } from "react-hook-form";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckIcon } from "@heroicons/react/20/solid";

function ProfessionalExperience() {
  const [exp, setExp] = useState({
    employers: [],
    total_experience: 0,
  });
  const [newData, setNewData] = useState([]);

  const { saveExp, getExp } = useProviderActions();
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getExp(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            //TODO as response incorrect
            setExp(res.data);
          }
        }
      });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setNewData([...newData, data]);
    reset();
  };

  const onSave = () => {
    const payload = { experiences: [...exp.employers, ...newData] };
    saveExp(payload);
  };

  return (
    <div className="my-4  px-10 max-md:px-2">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Professional Experience
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
            <div className="my-4 flex justify-between items-center max-md:flex-col">
              <input
                placeholder="Employer"
                {...register("employer_name")}
                className="w-72 border p-3 rounded-md  "
              />
              <input
                placeholder="designation"
                {...register("designation")}
                className="w-72 border p-3 rounded-md  "
              />
              <input
                placeholder="Year of Experience"
                {...register("years_of_experience")}
                type="number"
                className="w-72 border p-3 rounded-md  "
              />

              <button
                type="submit"
                className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Employer</TableCell>
                <TableCell align="center">Designation</TableCell>
                <TableCell align="center">Year of Experience</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...newData, ...exp.employers].map((exp, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {exp.employer_name}
                  </TableCell>
                  <TableCell align="center">{exp.designation}</TableCell>
                  <TableCell align="center">
                    {exp.years_of_experience}
                  </TableCell>
                  <TableCell align="center">
                    <CheckIcon width={16} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <div className="max-md:overflow-auto bg-gray_out">
          <div className="text-body_font_two font-semibold px-10">
            <span className="">Employer</span>
            <span className="">Designation</span>
            <span className="">Year of Experience</span>
            <span className="">Action</span>
          </div>
          <div className=" ">
            
            {[...newData, ...exp.employers].map((exp, idx) => {
              return (
                <div className="flex   py-1" key={idx}>
                  <span className="">{exp.employer_name}</span>
                  <span className="">{exp.designation}</span>
                  <span className="">{exp.years_of_experience}</span>
                  <span className="">Tick</span>
                </div>
              );
            })}
          </div>
        </div> */}
        <button
          onClick={onSave}
          className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
        >
          Save
        </button>
      </section>
    </div>
  );
}

export default ProfessionalExperience;
