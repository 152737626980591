import React from "react";

function HowToRegister() {
  return (
    <React.Fragment>
      <div className="mt-36 ml-5 text-4xl font-bold tracking-wider text-center capitalize text-slate-900 max-md:mt-10 max-md:max-w-full">
        Here are the steps to get started on Cure Culture
      </div>
      <div className="mt-24 w-full  max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 justify-center items-baseline max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow items-center pt-12  mt-10 rounded-bl-2xl rounded-br-2xl w-full capitalize bg-white rounded-3xl border border-black border-solid max-md:mt-8  ">
              <img
                alt=""
                loading="lazy"
                src="/assets/images/sign-up-btn-02.png"
                className="mt-2.5 max-w-full aspect-square w-[155px]"
              />
              <div className="z-10 justify-center px-8 py-6 mt-10 text-6xl font-extrabold tracking-widest whitespace-nowrap bg-emerald-300 rounded-xl text-slate-900 max-md:px-5 max-md:mt-10 max-md:text-4xl">
                1
              </div>
              <div className="flex flex-col self-stretch px-12 py-16 text-center rounded-none bg-blue-900 bg-opacity-10 max-md:px-5 -mt-10  rounded-bl-2xl rounded-br-2xl max-h-[300px] min-h-[300px]">
                <div className="mt-4 text-2xl font-extrabold tracking-wide leading-5 text-slate-900">
                  Sign Up
                </div>
                <div className="mt-9 mb-10 text-xl font-light leading-7 text-black">
                  Click the "Register as a Patient" button and fill out the
                  simple form.
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow items-center pt-12  mt-10 rounded-bl-2xl rounded-br-2xl w-full capitalize bg-white rounded-3xl border border-black border-solid max-md:mt-8 ">
              <img
                alt=""
                loading="lazy"
                src="/assets/images/img-choose-assistance-001.png"
                className="mt-2.5 max-w-full aspect-square w-[155px]"
              />
              <div className="z-10 justify-center px-8 py-6 mt-10 text-6xl font-extrabold tracking-widest whitespace-nowrap bg-emerald-300 rounded-xl text-slate-900 max-md:px-5 max-md:mt-10 max-md:text-4xl">
                2
              </div>
              <div
                className="flex flex-col self-stretch px-2 py-20 text-center rounded-none bg-blue-900 bg-opacity-10 max-md:px-5 -mt-10  rounded-bl-2xl rounded-br-2xl 
              
              max-h-[300px] min-h-[300px]
              "
              >
                <div className="mt-4 text-2xl font-extrabold tracking-wide leading-5 text-slate-900">
                  choose a Provider
                </div>
                <div className="mt-10 mb-10 text-xl font-light leading-7 text-black">
                  Select your preferred provider from the list, considering
                  their availability, expertise, and rates.
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow items-center pt-12  mt-10 rounded-bl-2xl rounded-br-2xl w-full capitalize bg-white rounded-3xl border border-black border-solid max-md:mt-8 ">
              <img
                alt="img-choose-assistance-001.png"
                loading="lazy"
                src="/assets/images/set-an-appointment-img.png"
                className="mt-2.5 max-w-full aspect-square w-[155px]"
              />
              <div className="z-10 justify-center px-8 py-6 mt-10 text-6xl font-extrabold tracking-widest whitespace-nowrap bg-emerald-300 rounded-xl text-slate-900 max-md:px-5 max-md:mt-10 max-md:text-4xl">
                3
              </div>
              <div
                className="flex flex-col self-stretch px-12 py-10 text-center rounded-none bg-blue-900 bg-opacity-10 max-md:px-5 -mt-10  rounded-bl-2xl rounded-br-2xl 
              
              max-h-[300px] min-h-[300px]
              "
              >
                <div className="mt-4 text-2xl font-extrabold tracking-wide leading-5 text-slate-900">
                  Set Up Appointment
                </div>
                <div className="mt-9 mb-10 text-xl font-light leading-7 text-black">
                  Use the provider's calendar to choose a date and time, then
                  select your payment plan.
                </div>
              </div>
            </div>
          </div>
     
        </div>
      </div>
    </React.Fragment>
  );
}

export default HowToRegister;
