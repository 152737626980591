import { useContext } from "react";
import { AuthContext } from "../App";
import { AUTH_HEADER, baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import { isAuthorize } from "./useUserActions";

function useContactActions() {
  // const { userDetails, updateUserContext } = useContext(AuthContext);
  const headers = AUTH_HEADER;

  const verifyAdditionalContact = (payload) => {
    return axios
      .post(`${baseURL}/providers/verify_additional_contact`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveAdditionalContacts = (payload) => {
    return axios
      .post(`${baseURL}/providers/save_additional_contacts`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const sendVerificationCode = (payload) => {
    return axios
      .post(`${baseURL}/providers/send_verification_code`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  // const getExitingCollabs = async () => {
  //   try {
  //     return await axios.get(`${baseURL}/collaborations`, headers);
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //     isAuthorize(err);
  //   }
  // };
  // const getCollabsRequests = async () => {
  //   try {
  //     return await axios.get(`${baseURL}/collaborations`, headers);
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //     isAuthorize(err);
  //   }
  // };
  // const searchProviderForCollab = async (queryString) => {
  //   try {
  //     return await axios.get(
  //       `${baseURL}/collaborations/users?search=${queryString}`,
  //       headers
  //     );
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //     isAuthorize(err);
  //   }
  // };

  // const sendCollabRequest = (drId) => {
  //   return axios
  //     .post(
  //       `${baseURL}/collaborations/send_request`,
  //       {
  //         dr_user_id: drId,
  //       },
  //       headers
  //     )
  //     .then((res) => {
  //       toast.success(res.data.messages);
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong");
  //       isAuthorize(err);
  //     });
  // };

  return {
    sendVerificationCode,
  };
}

export default useContactActions;
