import React from "react";
import Modal from "react-modal";

const modalCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function ModalComponent({ isOpen, style, className, contentLabel, body }) {
  return (
    <Modal
      isOpen={isOpen}
      style={modalCustomStyles}
      className={className}
      contentLabel={contentLabel}
    >
      {body}
    </Modal>
  );
}

export default ModalComponent;
