import React from "react";
import DashboardHoc from "../../DashboardHoc";
import Appointment from "./Appointment";

function Scheduling() {
  return (
    <DashboardHoc>
      <div className="w-full bg-white px-4">
        <Appointment />
      </div>
    </DashboardHoc>
  );
}

export default Scheduling;
