import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../../../../../App";
import useProviderActions from "../../../../../../../actions/useProviderActions";

function ProfileForPatients() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { userDetails } = useContext(AuthContext);
  const { saveProfile, getProfileData } = useProviderActions();
  const [defaultData, setDefaultData] = useState({
    introduction_for_patient: "",
    treatment: "",
    volunteer_work: "",
    research_interests: "",
    hobbies: "",
  });
  const onSave = (data) => {
    saveProfile({
      user_id: userDetails.user_id,
      ...data,
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProfileData(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setDefaultData(res.data);
          }
        }
      });
  }, []);
  return (
    <div className="my-4  px-10">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10">
        <form onSubmit={handleSubmit(onSave)}>
          <div className="text-xxl font-semibold  capitalize my-4">
            Profile For Patients
          </div>
          <div className=" flex flex-col gap-2">
            <div>
              <label className="text-gray_out_text">
                Introduction & Personal Statement
              </label>
              <textarea
                {...register("introduction_for_patient")}
                defaultValue={defaultData.introduction_for_patient}
                name="introduction_for_patient"
                className="w-full border rounded-lg pb-8"
              />
            </div>{" "}
            <div>
              <label className="text-gray_out_text">
                Treatment Philosophy & Patient Care Goals
              </label>
              <textarea
                {...register("treatment")}
                name="treatment"
                defaultValue={defaultData.treatment}
                className="w-full border rounded-lg pb-8"
              />
            </div>{" "}
            <div>
              <label className="text-gray_out_text">Volunteer Work</label>
              <textarea
                {...register("volunteer_work")}
                name="volunteer_work"
                defaultValue={defaultData.volunteer_work}
                className="w-full border rounded-lg pb-8"
              />
            </div>{" "}
            <div>
              <label className="text-gray_out_text">Research Interests</label>
              <textarea
                defaultValue={defaultData.research_interests}
                {...register("research_interests")}
                name="research_interests"
                className="w-full border rounded-lg pb-8"
              />
            </div>{" "}
            <div>
              <label className="text-gray_out_text">Hobbies</label>
              <textarea
                defaultValue={defaultData.hobbies}
                {...register("hobbies")}
                name="hobbies"
                className="w-full border rounded-lg pb-1"
              />
            </div>
          </div>
          <button
            type="submit"
            className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
          >
            Save
          </button>
        </form>
      </section>
    </div>
  );
}

export default ProfileForPatients;
