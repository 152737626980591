import { useContext } from "react";
import { AuthContext } from "../App";
import { AUTH_HEADER, baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import { isAuthorize } from "./useUserActions";
import moment from "moment";

function useProviderActions() {
  // const { userDetails, updateUserContext } = useContext(AuthContext);
  const headers = AUTH_HEADER;
  const verifyNPI = async (data) => {
    return axios
      .put(`${baseURL}/providers/verify_npi`, data, headers)
      .then((data) => {
        if (data.data.status === "failed") {
          toast.error(data.data.messages);
          data.data.data.remaining_attempts &&
            toast.error(
              `Only ${data.data.data.remaining_attempts} attempts left`
            );
          return data.data;
        }
        toast.success(data.data.messages);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };
  const verifySSN = async (data) => {
    return axios
      .put(`${baseURL}/providers/verify_ssn`, data, headers)
      .then((data) => {
        if (data.data.status === "failed") {
          toast.error(data.data.messages);
          data.data.data.remaining_attempts &&
            toast.error(
              `Only ${data.data.data.remaining_attempts} attempts left`
            );
          return data.data;
        }

        toast.success(data.data.messages);
        return data.data;
      })
      .catch((err) => {
        isAuthorize(err);
        toast.error("Something went wrong");
      });
  };
  const getAvailableSlots = async () => {
    try {
      return await axios.get(`${baseURL}/agent/time_slots`, headers);
    } catch (err) {
      isAuthorize(err);
      toast.error("Something went wrong");
    }
  };

  const getDocumentList = async () => {
    try {
      return await axios.get(`${baseURL}/providers/document/list`, headers);
    } catch (err) {
      isAuthorize(err);
      toast.error("Unable to fetch documents");
    }
  };

  const bookSlots = (body) => {
    return axios
      .post(`${baseURL}/agent/book_slot`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        isAuthorize(err);
        toast.error("unable to book slot");
      });
  };

  const cancelBookSlots = (body) => {
    toast.warn("Unable to Cancel slot");
  };

  const uploadDocument = async (payload) => {
    return axios
      .post(`${baseURL}/providers/document/upload`, payload, {
        ...headers,
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        return { error: false, res };
      })
      .catch((err) => {
        toast.error("Failed to upload document");
        isAuthorize(err);
        return { error: true, err };
      });
  };

  const deleteDocument = (id) => {
    return axios
      .delete(`${baseURL}/providers/document/delete/${id}`, {
        ...headers,
      })
      .then((res) => {
        toast.success("Document Deleted");
        return res;
      })
      .catch((err) => {
        toast.error("Failed to delete document");
        isAuthorize(err);
        return err;
      });
  };

  const completeRegistration = (id) => {
    return axios
      .put(
        `${baseURL}/providers/complete_registration`,
        { complete: true },
        {
          ...headers,
        }
      )
      .then((res) => {
        if (res?.data && res?.data?.status && res?.data?.status === "failed") {
          toast.error(res?.data?.messages);
          return { response: res, error: true };
        }

        toast.success(res?.data?.messages);
        return { response: res, error: false };
      })
      .catch((err) => {
        toast.error("Failed to complete Registration");
        isAuthorize(err);
        return err;
      });
  };

  const getWidgets = async () => {
    try {
      return await axios.get(`${baseURL}/providers/get_widgets`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getProviderDetails = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/full_details/${id}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getReviews = async (id) => {
    try {
      return await axios.get(`${baseURL}/providers/reviews`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getMasterSpecialtiesList = async () => {
    try {
      return await axios.get(`${baseURL}/master/specialities`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getProviderSpecialtiesList = async (id) => {
    try {
      return await axios.get(`${baseURL}/providers/get_specialities`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getSpokenLanguages = async (id) => {
    try {
      return await axios.get(`${baseURL}/providers/get_languages`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const saveLanguages = (body) => {
    return axios
      .post(`${baseURL}/providers/save_languages`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveExp = (body) => {
    return axios
      .post(`${baseURL}/providers/save_professional_experiences`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const getExp = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/professional_experiences/${id}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getConsultationHours = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/list_consultation_hours/${id}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const saveConsultationHours = (body) => {
    return axios
      .post(`${baseURL}/providers/save_consultation_hours`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveBankDetails = (body) => {
    return axios
      .post(`${baseURL}/providers/save_bank_details`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const getBankDetails = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/get_bank_details/${id}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getAssessmentCharges = async (id) => {
    try {
      return await axios.get(`${baseURL}/providers/get_charges/${id}`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getAverageAssessmentCharges = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/average_provider_charges`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const saveAssessmentCharges = (body) => {
    return axios
      .post(`${baseURL}/providers/save_charges`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const updateSpecialties = (body) => {
    return axios
      .post(`${baseURL}/providers/save_specialities`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveProfile = (body) => {
    return axios
      .post(`${baseURL}/providers/save_profile`, body, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const removeWelcomeMessage = () => {
    return axios
      .post(`${baseURL}/users/update_welcome_message`, {}, headers)
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const csaFormApplyOnline = (payload) => {
    return axios
      .put(`${baseURL}/providers/controlled_substance`, payload, headers)
      .then(() => {
        window.location.assign("/");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const csaFormApplyViaForm = (payload) => {};

  const csaFormDeny = (payload) => {};

  const updateWidgets = (payload) => {
    return axios
      .post(`${baseURL}/providers/save_widgets`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveProfessionalMembership = (payload) => {
    return axios
      .post(
        `${baseURL}/providers/save_professional_membership`,
        payload,
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const getProfessionalMembership = async (id) => {
    try {
      return await axios.get(
        `${baseURL}/providers/get_professional_membership/${id}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getProfileData = async (id) => {
    try {
      return await axios.get(`${baseURL}/providers/profile/${id}`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getMyAppointments = async (date = moment().format("YYYY-MM-DD")) => {
    try {
      return await axios.get(
        `${baseURL}/providers/appointments/?date=${date}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const getMyPatients = async () => {
    try {
      return await axios.get(
        `${baseURL}/providers/patients`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };


  return {
    verifyNPI,
    verifySSN,
    getAvailableSlots,
    bookSlots,
    uploadDocument,
    getDocumentList,
    deleteDocument,
    getReviews,
    completeRegistration,
    cancelBookSlots,
    getWidgets,
    getProviderDetails,
    getMasterSpecialtiesList,
    getProviderSpecialtiesList,
    updateSpecialties,
    saveLanguages,
    getSpokenLanguages,
    updateWidgets,
    saveProfile,
    getProfileData,
    removeWelcomeMessage,
    csaFormApplyOnline,
    csaFormApplyViaForm,
    csaFormDeny,
    saveBankDetails,
    getBankDetails,
    saveAssessmentCharges,
    getAssessmentCharges,
    getAverageAssessmentCharges,
    getConsultationHours,
    saveConsultationHours,
    saveExp,
    getExp,
    getMyAppointments,
    saveProfessionalMembership,
    getProfessionalMembership,
    getMyPatients
  };
}

export default useProviderActions;
