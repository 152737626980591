import React, { useCallback, useMemo, useState } from "react";

import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CreateEvent from "./CreateEvent";
import SchedulingActionComponent from "./SchedulingActionComponent";

function Appointment() {
  const localizer = momentLocalizer(moment);
  const myEventsList = [
    {
      title: "Appointment one",
      start: moment("22/08/2024"),
      end: moment("22/08/2024"),
    },
  ];
  const [myEvents, setEvents] = useState(myEventsList);

  const [openCreateModel, setOpenCreateModel] = React.useState(false);
  const [editing, setEditing] = React.useState({});

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setOpenCreateModel(true);
      setEditing({ start, end });
      // const title = window.prompt('New Event name')
      // console.log("={ start, end }=",{ start, end })
      // if (title) {
      //   // setEvents((prev) => [...prev, { start, end, title }])
      // }
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  );

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );
  const handleEventCreation = (payload) => {
    console.log(payload);
    setEvents((prev) => [
      ...prev,
      { start: payload.startTime, end: payload.endTime, title: "Available at" },
    ]);
  };

  return (
    <div>
      <div>
        <CreateEvent
          open={openCreateModel}
          defaultTimes={editing}
          setOpen={setOpenCreateModel}
          handleEventCreation={handleEventCreation}
        />
      </div>
      <div className="flex w-full">
        {/* <div><SchedulingActionComponent /></div> */}

        <div className="w-full">
          <img
            src="/assets/icons/provider/create-event-button.png"
            alt=""
            height={150}
            width={150}
            onClick={() => setOpenCreateModel(true)}
            className="mb-2 cursor-pointer"
          ></img>
          <Calendar
            localizer={localizer}
            events={myEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600 }}
            defaultDate={defaultDate}
            defaultView={Views.WEEK}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            selectable
            scrollToTime={scrollToTime}
            step={15}
            timeslots={8}
          />
        </div>
      </div>
    </div>
  );
}

export default Appointment;
