import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ProfilePicture from "../../../../components/ProfilePicture";
import useCollabActions from "../../../../actions/useCollabActions";

function NewCollaboratorList() {
  const [requests, setRequestList] = React.useState([]);
  const { getCollabsRequests } = useCollabActions();

  React.useEffect(() => {
    getCollabsRequests().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setRequestList(res.data);
        }
      }
    });
  }, []);
  return (
    <div className="bg-white w-full ease-in duration-300  rounded shadow-lg">
      <div className="text-subtitle_one px-4 py-4">
        New Collaboration Requests
      </div>
      <div className="relative overflow-x-auto max-md:max-w-80">
        {requests && requests.length ? (
          <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Message
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="">
              {requests.map(({ picture, first_name, full_name }) => {
                return (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4 flex items-center gap-2">
                      {picture ? (
                        <img src={picture} width="35px" height="35px" />
                      ) : (
                        <ProfilePicture
                          name={first_name}
                          width="35px"
                          height="35px"
                        />
                      )}
                      {full_name}
                    </td>
                    <td className="px-6 ">
                      <img
                        src={"/assets/icons/provider/new-email.png"}
                        className="w-4 h-4"
                      />
                    </td>{" "}
                    <td className="px-6 py-4">
                      <span className="flex items-center gap-16">
                        <span className="flex items-center gap-2 text-mid_tone_three ">
                          <img
                            alt=""
                            loading="lazy"
                            src="/assets/images/verified-green-tick.png"
                            className="w-5 h-4"
                          />
                          Accept Request
                        </span>
                      </span>
                    </td>{" "}
                    <td className="px-6 py-4">
                      <span className="flex items-center gap-16 text-complementary_accent_three">
                        <span className="flex items-center gap-2">
                          <img
                            alt=""
                            loading="lazy"
                            src="/assets/images/verified-red-cross.png"
                            className="w-5 h-4"
                          />
                          Deny Request
                        </span>
                      </span>
                    </td>
                  </tr>
                );
              })}
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 flex items-center gap-2">
                  <ProfilePicture />
                  Florine Marquardt
                </td>
                <td className="px-6 ">
                  <img
                    src={"/assets/icons/provider/new-email.png"}
                    className="w-4 h-4"
                  />
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16">
                    <span className="flex items-center gap-2 text-mid_tone_three ">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-green-tick.png"
                        className="w-5 h-4"
                      />
                      Accept Request
                    </span>
                  </span>
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16 text-complementary_accent_three">
                    <span className="flex items-center gap-2">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-red-cross.png"
                        className="w-5 h-4"
                      />
                      Deny Request
                    </span>
                  </span>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 flex items-center gap-2">
                  <ProfilePicture />
                  Florine Marquardt
                </td>
                <td className="px-6 ">
                  <img
                    src={"/assets/icons/provider/new-email.png"}
                    className="w-4 h-4"
                  />
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16">
                    <span className="flex items-center gap-2 text-mid_tone_three ">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-green-tick.png"
                        className="w-5 h-4"
                      />
                      Accept Request
                    </span>
                  </span>
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16 text-complementary_accent_three">
                    <span className="flex items-center gap-2">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-red-cross.png"
                        className="w-5 h-4"
                      />
                      Deny Request
                    </span>
                  </span>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 flex items-center gap-2">
                  <ProfilePicture />
                  Florine Marquardt
                </td>
                <td className="px-6 ">
                  <img
                    src={"/assets/icons/provider/new-email.png"}
                    className="w-4 h-4"
                  />
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16">
                    <span className="flex items-center gap-2 text-mid_tone_three ">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-green-tick.png"
                        className="w-5 h-4"
                      />
                      Accept Request
                    </span>
                  </span>
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16 text-complementary_accent_three">
                    <span className="flex items-center gap-2">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-red-cross.png"
                        className="w-5 h-4"
                      />
                      Deny Request
                    </span>
                  </span>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 flex items-center gap-2">
                  <ProfilePicture />
                  Florine Marquardt
                </td>
                <td className="px-6 ">
                  <img
                    src={"/assets/icons/provider/new-email.png"}
                    className="w-4 h-4"
                  />
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16">
                    <span className="flex items-center gap-2 text-mid_tone_three ">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-green-tick.png"
                        className="w-5 h-4"
                      />
                      Accept Request
                    </span>
                  </span>
                </td>{" "}
                <td className="px-6 py-4">
                  <span className="flex items-center gap-16 text-complementary_accent_three">
                    <span className="flex items-center gap-2">
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-red-cross.png"
                        className="w-5 h-4"
                      />
                      Deny Request
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>No new Requests Found</div>
        )}
      </div>
    </div>
  );
}

export default NewCollaboratorList;
