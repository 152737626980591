import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../App";

function AppSpecialties() {
  const { userDetails } = useContext(AuthContext);

  const [specialtiesList, setSpecialtiesList] = useState([]);
  const { getProviderSpecialtiesList } = useProviderActions();

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProviderSpecialtiesList(userDetails.user_id).then((res) => {
        if (res.status === 200 && res.data && res.data.length) {
          if (res.data) {
            // setSpecialtiesList(res.data);
          }
        }
      });
  }, []);
  if (specialtiesList && specialtiesList.length)
    return (
      <div className="p-10">
        <span
          className="text-body_font_two  font-semibold"
          style={{
            color: "#494A52",
          }}
        >
          Specialties
        </span>
        <div className="flex flex-wrap gap-4 my-4">
          {specialtiesList &&
            specialtiesList.map((specialty, idx) => {
              return (
                <span key={idx} className="py-1 px-3 bg-white rounded-xl">
                  {specialty}
                </span>
              );
            })}
        </div>
      </div>
    );
  else {
    return null;
  }
}

export default AppSpecialties;
