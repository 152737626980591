import React, { useContext, useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../main/components/Footer";
import PersonalDetailsVerification from "./PersonalDetailsVerification";
import NPIVerification from "./NPIVerification";
import DocumentSubmission from "./DocumentSubmission";
import MedicalMalpracticeInsurance from "./MedicalMalpracticeInsurance";
import classNames from "classnames";
import { AuthContext } from "../../../App";
import useProviderActions from "../../../actions/useProviderActions";
import { unstable_usePrompt, useNavigate } from "react-router-dom";
import useUserActions from "../../../actions/useUserActions";
import CSAForm from "./CSAForm";
import { CircularProgress } from "@mui/material";
import ModalError from "../../../components/modals/ModalError";
import ProviderCategory from "./ProviderCategory";

function ProviderRegistrationForm() {
  const [isValid, setValid] = useState(false);
  const [reRender, forceUpdate] = useState(false);
  const { user, updateUserContext, iSMd } = useContext(AuthContext);
  const [userDetails, setUser] = useState(user);
  const [isLoading, setLoading] = useState(false);
  const [showCSA, setShowCSA] = useState(false);
  const [showNPIError, setShowNPIError] = useState(false);

  // const [checkFormValidity, callFormValidityCheck] = useState(false);
  const checkIsValid = () => {
    setValid(true);
  };
  const navigate = useNavigate();
  const { update: updatePersonalDetails, getUser } = useUserActions();
  const onCompletedRegistration = async () => {
    setLoading(true);

    completeRegistration().then((res) => {
      if (res.error && res.response && res.response.data) {
        const {
          response: { data },
        } = res;
        if (
          data.status === "failed" &&
          data.messages === "Kindly Provide NPI"
        ) {
          setShowNPIError(true);
          setLoading(false);
          return;
        }
      }
      if (!res.error) {
        const response = res.response.data;
        if (response.status === "success") {
          if (response.is_md_app === "APP") {
            navigate("/provider/dashboard");
            return;
          }
          {
            getUser();
            navigate("csa");
            return;
          }
        } else {
          console.log("at-error");
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await getUser();

      setUser(user.data.user);
    };
    getUserInfo();
  }, [reRender]);

  const [isEditingPersonalDetails, setIsEditingPersonalDetails] =
    useState(false);

  const [updates, setUpdates] = useState({});

  const handleChange = (name, value) => {
    setUpdates({ ...updates, [name]: value });
  };

  const handlePersonalDetails = async (data) => {
    const response = await updatePersonalDetails({ ...data, ...updates });
    const user = await getUser();

    setIsEditingPersonalDetails(false);
    setUser(user?.data?.user);
  };
  const { completeRegistration } = useProviderActions();
  if (showNPIError) {
    return (
      <ModalError
        message="Please provide NPI (National Provider Identifier)"
        close={() => {
          setShowNPIError(false);
        }}
      />
    );
  }
  if (isLoading) {
    return (
      <div>
        <div className="">
          <div className="flex flex-col items-center  bg-zinc-100">
            <div className="flex flex-col self-stretch w-full max-md:p-4 max-md:max-w-full flex-center">
              <Header />
              <div className="px-24 my-4 max-md:px-4">
                <div className="text-3xl  max-md:px-10">
                  Registration & Verification
                </div>
                <div className="text-body_font_one pt-4 max-md:px-10">
                  We would like you to complete your registration and
                  verification here, before we proceed. We understand that you
                  may have questions or concerns. We would like to address them
                  to the best of our ability. Write to us at{" "}
                  <span className="text-mid_tone_two cursor-pointer">
                    help@cure-culture.com
                  </span>
                </div>
                <div className="w-full flex justify-center my-10">
                  <CircularProgress />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="flex flex-col items-center  bg-zinc-100">
          <div className="flex flex-col self-stretch w-full max-md:p-5 max-md:max-w-full flex-center">
            <Header isPatent={true} />
            <div className="px-24 my-4 max-md:px-0">
              <div className="text-3xl  max-md:px-10">
                Registration & Verification
              </div>
              <div className="text-body_font_one pt-4 max-md:px-10">
                We would like you to complete your registration and verification
                here, before we proceed. We understand that you may have
                questions or concerns. We would like to address them to the best
                of our ability. Write to us at{" "}
                <span className="text-mid_tone_two cursor-pointer">
                  help@cure-culture.com
                </span>
              </div>
              <PersonalDetailsVerification
                checkFormValidityCheck={checkIsValid}
                onSubmit={handlePersonalDetails}
                setIsEditing={setIsEditingPersonalDetails}
                isEditing={isEditingPersonalDetails}
                setUser={setUser}
                handleChange={handleChange}
                userDetails={userDetails}
              />
              <NPIVerification />
              <ProviderCategory />
              {userDetails?.provider_details?.category_id ? (
                <DocumentSubmission />
              ) : null}
              {userDetails?.phone_number ? (
                <MedicalMalpracticeInsurance
                  checkFormValidityCheck={checkIsValid}
                />
              ) : null}
              <div className="flex justify-center ">
                <button
                  onClick={() => isValid && onCompletedRegistration()}
                  className={classNames(
                    {
                      "text-light_tone_one cursor-not-allowed ": !isValid,
                      "submit-btn-bg-color text-white": isValid,
                    },
                    "border  py-3 px-5 rounded-xl w-72"
                  )}
                >
                  Submit
                </button>
              </div>
              {!isValid ? (
                <div className="flex justify-center max-md:px-10 text-complementary_accent_three">
                  Add Phone number, Provider category, NPI and book at least
                  three slots to submit
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer isPatent={true} />
      </div>
    </div>
  );
}

export default ProviderRegistrationForm;
