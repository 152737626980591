import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../../App";
import useProviderActions from "../../../../../../../actions/useProviderActions";

function LanguageSpoken() {
  const [isAdding, setAdding] = useState(false);
  const [langs, setLangs] = useState([]);
  const { saveLanguages, getSpokenLanguages } = useProviderActions();
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getSpokenLanguages(userDetails.user_id).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data) {
            setLangs(res.data);
          }
        }
      });
  }, []);
  const handleChange = (value, idx) => {
    const temp = [...langs];
    temp[idx] = value;
    setLangs([...temp]);
  };

  const onSave = () => {
    saveLanguages({
      user_id: userDetails.user_id,
      languages: langs,
    });
  };

  return (
    <div className="my-4  px-10 max-md:px-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Language Spoken
        </div>
        <div className=" p-2 rounded-md text-gray_out_text my-2">
          <div className="my-4 flex justify-between items-center flex-wrap">
            {langs.map((item, idx) => (
              <input
                key={idx}
                onChange={(e) => handleChange(e.target.value, idx)}
                placeholder="Enter Language"
                className=" border p-3 w-72 rounded-md  bg-gray_out my-2 max-md:w-80"
                defaultValue={item}
              />
            ))}
            {isAdding ? (
              <input
                placeholder="Enter Language"
                onBlur={(e) => {
                  setLangs([...langs, e.target.value]);
                  setAdding(false);
                }}
                className="w-72 border p-3 rounded-md  bg-gray_out max-md:w-80"
              />
            ) : null}
            <div className="w-72 max-md:w-80">
              <img
                className="cursor-pointer"
                onClick={() => setAdding(true)}
                src="/assets/icons/provider/add-icon.png"
              ></img>
            </div>
          </div>
        </div>
        <button
          onClick={onSave}
          className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
        >
          Save
        </button>
      </section>
    </div>
  );
}

export default LanguageSpoken;
