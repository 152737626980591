import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import useUserActions from "../../../../../actions/useUserActions";
import { Button, Typography } from "@mui/material";
import ChangePassword from "./ChangePassword";
import EmailVerificationModal from "../../../../../components/modals/EmailVerificationModal";
import PhoneVerificationModal from "../../../../../components/modals/PhoneVerificationModal";
import useContactActions from "../../../../../actions/useContactActions";

function ProfileSecurity() {
  const { update: updatePersonalDetails, getUser } = useUserActions();

  const [userDetails, setUser] = useState(null);
  useEffect(() => {
    const getUserInfo = async () => {
      const user = await getUser();
      if (userDetails === null) setUser(user.data.user);
    };
    getUserInfo();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    delete data.email;
    updatePersonalDetails(data);
  };
  const [changingPassword, showPasswordModal] = useState(false);
  const [isOpenVerifyEmail, setOpenVerifyEmail] = useState(false);
  const [isOpenVerifyPhone, setOpenVerifyPhone] = useState(false);
  const { sendVerificationCode } = useContactActions();
  const handleEmailVerifyClick = () => {
    const payload = {};

    setOpenVerifyEmail(true);
  };
  return (
    <div className="h-full bg-white w-11/12 shadow-lg rounded-lg py-10  ">
      <ChangePassword
        isOpen={changingPassword}
        onClose={() => showPasswordModal(false)}
      />

      <EmailVerificationModal
        isOpen={isOpenVerifyEmail}
        onClose={() => setOpenVerifyEmail(false)}
        email={userDetails?.email}
      />
      <PhoneVerificationModal
        isOpen={isOpenVerifyPhone}
        onClose={() => setOpenVerifyPhone(false)}
        number={userDetails?.phone_number}
      />
      <span className="block font-semibold text-xxl	pl-10 ">Security</span>
      {userDetails === null ? (
        <ClipLoader
          color={"black"}
          loading={true}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <form className="w-full  px-10 mt-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 text-body_font_three ">
            <div className="flex max-md:w-full max-md:flex-col max-md:gap-4">
              <div className="w-1/2 max-md:w-full ">
                <div>
                  <label className="block">Email</label>
                  <input
                    className="w-10/12 border p-2  rounded-md "
                    {...register("email", {
                      required: "email",
                    })}
                    value={userDetails.email}
                    readOnly
                    type="text"
                  />
                </div>
                <Button variant="text" onClick={() => setOpenVerifyEmail(true)}>
                  Verify Email
                </Button>
              </div>{" "}
              <div className="w-1/2 max-md:w-full ">
                <div>
                  <label>Phone Number</label>
                  <input
                    className="w-10/12 border p-2  rounded-md "
                    {...register("phone_number", {
                      required: "phone_number",
                    })}
                    defaultValue={userDetails.phone_number}
                    type="text"
                  />
                </div>
                <Button onClick={() => setOpenVerifyPhone(true)} variant="text">
                  Verify Phone
                </Button>
              </div>
            </div>
            <div className="w-1/2 block max-md:w-full ">
              <Button
                onClick={() => showPasswordModal(true)}
                variant="contained"
                color="warning"
              >
                Change Password
              </Button>
            </div>
            <div className="w-1/2 block max-md:w-full ">
              <div>
                <label className=" block">SSN No. </label>
                <input
                  className="w-10/12 border p-2  rounded-md "
                  {...register("ssn", {
                    required: "ssn",
                  })}
                  defaultValue={userDetails.provider_details.ssn}
                  type="text"
                />
              </div>
            </div>
            <div>
              <div className="text-body_font_two font-semibold">Address</div>
              <div className="flex gap-4 ">
                <div className="w-full flex max-md:flex-col max-md:gap-4">
                  <div className="w-1/2 max-md:w-full">
                    <div>
                      <label className="block">Address Line 1</label>
                      <input
                        className="w-10/12 border p-2  rounded-md "
                        {...register("address_line1", {
                          required: "address_line1",
                        })}
                        value={userDetails.address_line1}
                        type="text"
                      />
                    </div>
                  </div>{" "}
                  <div className="w-1/2 max-md:w-full">
                    <div>
                      <label>Address Line 2</label>
                      <input
                        className="w-10/12 border p-2  rounded-md "
                        {...register("address_line2", {
                          required: "address_line2",
                        })}
                        defaultValue={userDetails.address_line2}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" flex my-4 max-md:flex-col max-md:gap-4">
                <div className="w-1/2 max-md:w-full">
                  <div>
                    <label className="block">City</label>
                    <input
                      className="w-10/12 border p-2  rounded-md "
                      {...register("city", {
                        required: "city",
                      })}
                      defaultValue={userDetails.city}
                      type="text"
                    />
                  </div>
                </div>{" "}
                <div className="w-1/4 max-md:w-full">
                  <div>
                    <label className="block">State</label>
                    <input
                      className="w-10/12 border p-2  rounded-md "
                      {...register("state", {
                        required: "state",
                      })}
                      defaultValue={userDetails.state}
                      type="text"
                    />
                  </div>
                </div>{" "}
                <div className="w-1/4 max-md:w-full">
                  <div>
                    <label className="block">Zip</label>
                    <input
                      className="w-10/12 border p-2  rounded-md "
                      {...register("zip_code", {
                        required: "zip_code",
                      })}
                      defaultValue={userDetails.zip_code}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="submit"
            className={classNames(
              "text-button_font p-4 submit-btn-bg-color  hover:border-transparent  border rounded-md w-24 my-4 text-light_tone_one bg-mid_tone_two cursor-pointer "
            )}
            value="Save"
          ></input>
        </form>
      )}
    </div>
  );
}

export default ProfileSecurity;
