import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import { useForm } from "react-hook-form";

function ProfessionalExperience() {
  const [exp, setExp] = useState({
    employers: [],
    total_experience: 0,
  });
  const [newData, setNewData] = useState([]);

  const { saveExp, getExp } = useProviderActions();
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getExp(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            //TODO as response incorrect
            setExp(res.data);
          }
        }
      });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setNewData([...newData, data]);
    reset();
  };

  const onSave = () => {
    const payload = { experiences: [...exp.employers, ...newData] };
    saveExp(payload);
  };

  return (
    <div className="my-4  px-10">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10">
        <div className="text-xxl font-semibold  capitalize">
          Professional Experience
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
            <div className="my-4 flex justify-between items-center">
              <input
                placeholder="Employer"
                {...register("employer_name")}
                className="w-72 border p-3 rounded-md  "
              />
              <input
                placeholder="designation"
                {...register("designation")}
                className="w-72 border p-3 rounded-md  "
              />
              <input
                placeholder="Year of Experience"
                {...register("years_of_experience")}
                type="number"
                className="w-72 border p-3 rounded-md  "
              />

              <button
                type="submit"
                className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className="text-body_font_two flex font-semibold pl-10">
          <span className="w-[500px]">Employer</span>
          <span className="w-96">Designation</span>
          <span className="w-52">Year of Experience</span>
          <span className="pl-10 w-52 ">Action</span>
        </div>
        <div className="bg-gray_out p-2 rounded-md my-2 flex flex-col gap-3 pl-10">
          {[...newData, ...exp.employers].map((exp, idx) => {
            return (
              <div className="flex   py-1" key={idx}>
                <span className="w-[500px]">{exp.employer_name}</span>
                <span className="w-96">{exp.designation}</span>
                <span className="w-52 pl-16">{exp.years_of_experience}</span>
                <span className="w-52 pl-10">Tick</span>
              </div>
            );
          })}
        </div>
        <button
          onClick={onSave}
          className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
        >
          Save
        </button>
      </section>
    </div>
  );
}

export default ProfessionalExperience;
