import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import useProviderActions from "../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../App";

const BankDetailsForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();
  const [bankDetails, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { userDetails } = useContext(AuthContext);

  const { saveBankDetails, getBankDetails } = useProviderActions();
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getBankDetails(userDetails.user_id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setData(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  const onSubmit = (data) => {
    if (data.bankAccountNumber === data.confirmBankAccount) {
      const payload = {
        routing_number: data.bankAccountNumber,
        account_number: data.routing_number,
      };
      saveBankDetails(payload);
    }
  };
  return (
    <div className="h-full bg-white w-11/12 shadow-lg rounded-lg py-10">
      <span className="block font-semibold text-xxl	pl-10">Bank Details</span>

      {isLoading ? (
        <div className="px-10 flex justify-center">
          <ClipLoader
            color={"black"}
            loading={true}
            size={40}
            aria-label="Loading bank details"
            data-testid="Loading bank details"
          />{" "}
        </div>
      ) : (
        <form className="w-full  px-10 mt-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap max-md:flex-col max-md:gap-4">
            <div className="w-1/2 max-md:w-full">
              <div>
                <label>Bank Account Number</label>
                <input
                  className="w-10/12 border p-2 mt-4 rounded-md max-md:m-0"
                  {...register("bankAccountNumber", {
                    required: "Bank account number is required",
                  })}
                  defaultValue={bankDetails.account_number}
                  type="text"
                />

                {errors.bankAccountNumber && (
                  <p className="text-complementary_accent_three">
                    {errors.bankAccountNumber.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-1/2 max-md:w-full">
              <div>Routing Number </div>
              <input
                className="w-10/12 border p-2 mt-4 rounded-md max-md:m-0"
                type="text"
                defaultValue={
                  bankDetails.routing_number ? bankDetails.routing_number : ""
                }
                {...register("routing_number", { required: true })}
              />
            </div>
            <div className="w-1/2 max-md:w-full">
              <label>Confirm Account Number</label>
              <input
                defaultValue={bankDetails.account_number}
                className="w-10/12 border p-2 mt-4 rounded-md max-md:m-0"
                {...register("confirmBankAccount", {
                  required: "Please confirm bank account number",

                  validate: {
                    matchesBankAccount: (value) =>
                      value === getValues("bankAccountNumber") ||
                      "Bank account numbers do not match",
                  },
                })}
                type="text"
              />
              {errors.confirmBankAccount && (
                <p className="text-complementary_accent_three">
                  {errors.confirmBankAccount.message}
                </p>
              )}
            </div>
          </div>
          <input
            type="submit"
            className={classNames(
              "text-button_font p-4 submit-btn-bg-color  hover:border-transparent  border rounded-md w-24 my-4 text-light_tone_one bg-mid_tone_two cursor-pointer "
            )}
            value="Save"
          ></input>
        </form>
      )}
    </div>
  );
};

export default BankDetailsForm;
